@import './fonts/typography.css';

html {
  font-family: Inter, sans-serif;
  background-color: #0c0c0c;
  overflow-x: hidden;
  height: 100%;
}

body {
  font-size: 15px; /* base font size, our p is 15px */
  color: white;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

/* hide scrollbar */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1 {
  font-family: Hobeaux;
  font-style: normal;
  font-weight: 900;
  font-size: 1.733rem;
  margin: 0;
}

h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  margin: 0;
}

h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.867rem;
  margin: 0;
}

h4 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1.467rem;
  margin: 0;
}

h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
}

h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.133rem;
  margin: 0;
}

p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
}

p.p2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  margin: 0;
}

p.button1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0;
}

p.button2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.067rem;
  margin: 0;
}

a {
  letter-spacing: 0.025em;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-size: 89%;
  font-family: Inter;
  font-weight: 500;
}

button {
  letter-spacing: 0.025rem;
}

input[type='date'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

svg {
  vertical-align: top;
}
